<template>
  <div class="dcpic">
    <Header class="app-title" theme="dark" @click="goBack">
      <template>华夏AI</template>
    </Header>
    <div class="container">
      <div class="dcpic-text">
        <img src="../../assets/images/dcpic/text.png" />
      </div>
      <div v-if="picStatus == 1">
        <div class="dcpic-title">
          <img src="../../assets/images/dcpic/title.png" />
        </div>
        <div class="dcpic-border">
          <textarea
            :placeholder="placeholder"
            v-model="textareaValue"
            @input="textareaChange"
          ></textarea>
        </div>
        <div class="dcpic-note">*建议输入英文，识别更精准</div>
        <div class="dcpic-botton-1" @click="startCreate()" v-if="buttonStatus">
          <div>开始生成</div>
        </div>
        <div class="dcpic-botton-2" v-else>
          <div>开始生成</div>
        </div>
      </div>
      <div v-if="picStatus == 2">
        <div class="dcpic1-border">
          <div>{{ showPrompt }}</div>
          <div class="dcpic1-border-after"></div>
          <div class="dcpic1-border-before"></div>
          <div class="num-tag">
            <img :src="showUrl" />
            <div class="num-tag-1">1</div>
            <div class="num-tag-2">2</div>
            <div class="num-tag-3">3</div>
            <div class="num-tag-4">4</div>
          </div>
        </div>
        <div class="dcpic1-title">在四张图片中选择一张生成：</div>
        <div class="dcpic1-btn">
          <div class="tag" @click="checkPic($event, 'u', 1)">选择一</div>
          <div class="tag" @click="checkPic($event, 'u', 2)">选择二</div>
          <div class="tag" @click="checkPic($event, 'u', 3)">选择三</div>
          <div class="tag" @click="checkPic($event, 'u', 4)">选择四</div>
        </div>
        <div class="dcpic1-title">选择一张作为模板重新生成四张：</div>
        <div class="dcpic1-btn">
          <div class="tag" @click="checkPic($event, 'v', 1)">模版一</div>
          <div class="tag" @click="checkPic($event, 'v', 2)">模版二</div>
          <div class="tag" @click="checkPic($event, 'v', 3)">模版三</div>
          <div class="tag" @click="checkPic($event, 'v', 4)">模版四</div>
        </div>
        <div class="dcpic-botton-1">
          <div @click="checkNext()">下一步</div>
        </div>
        <div class="gobackBtn" @click="setPicLost">
          返回上一步<van-icon name="arrow" />
        </div>
      </div>
      <div v-if="picStatus == 3">
        <div class="dcpic1-border">
          <div>{{ showPrompt }}</div>
          <div class="dcpic1-border-after"></div>
          <div class="dcpic1-border-before"></div>
          <div class="num-tag">
            <img :src="showUrl" />
          </div>
        </div>
        <div class="dcpic1-mint">
          <div>
            藏品铸造费：<span>¥{{ payPrice }}</span>
          </div>
        </div>
        <div class="dcpic-botton-1" v-if="this.$route.query.token">
          <div @click="showCenter = true">铸造为藏品</div>
        </div>
        <div class="dcpic-botton-2" v-else>
          <div>铸造为藏品</div>
        </div>
        <div class="gobackBtn" @click="setPicLost">放弃铸造</div>
        <div class="dcpic1-note">
          *铸造为藏品后，可申请开通转卖或二次创作，创造收益
        </div>
        <van-overlay :show="showCenter" @click="showCenter = false">
          <div class="show-center-class" @click.stop>
            <div class="aipic-title">藏品名称</div>
            <div class="aipic-name">
              <input
                v-model="aipicName"
                placeholder="输入藏品名称（10字以内）"
              />
            </div>
            <div class="aipic-save">
              <van-button block type="primary" class="blue" @click="savePic()"
                >铸造为藏品</van-button
              >
            </div>
          </div>
        </van-overlay>
        <van-overlay :show="saveSuccess">
          <div
            class="show-center-class show-center-class-1"
            @click="saveSuccess = false"
          >
            <div class="aipic-title">提示</div>
            <div class="aipic-content">藏品生成成功</div>
            <div class="aipic-save">
              <van-button block type="primary" class="blue">确认</van-button>
            </div>
          </div>
        </van-overlay>
        <van-popup v-model="show" position="bottom">
          <div class="pay-pop">
            <div class="pay-title">支付铸造费</div>
            <div class="pay-cost"><span>¥</span>{{ payPrice }}</div>
            <div class="pay-balance">
              <div>平台钱包（余额：{{ anyUserinfo.money }}）</div>
              <div>
                <input type="radio" checked />
              </div>
            </div>
            <div class="dcpic-botton-1 margin-bottom">
              <div
                @click="
                  showPwd = true;
                  show = false;
                "
              >
                铸造为藏品
              </div>
            </div>
          </div>
        </van-popup>
        <van-overlay :show="showPwd">
          <div class="wap">
            <div class="wap_affirm">
              <div
                @click="
                  showPwd = false;
                  value = '';
                "
                class="cha"
              >
                ×
              </div>
              <div>请输入交易密码</div>
              <div class="forgotPwd"></div>
            </div>
            <div class="wap_form">订单金额</div>
            <div class="wap_num">￥{{ payPrice }}</div>
            <div class="wap_balance">
              华夏钱包<span>(余额￥{{ anyUserinfo.money }})</span>
            </div>
            <!-- 密码输入框 -->
            <van-password-input
              :value="value"
              :error-info="errorInfo"
              :gutter="10"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
            />
            <!-- 数字键盘 -->
            <van-number-keyboard
              :show="showKeyboard"
              @blur="showKeyboard = true"
              @input="onInput"
              @delete="onDelete"
            />
          </div>
        </van-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import Axios from "axios";

export default {
  name: "aipic",
  data() {
    return {
      picStatus: 1,
      placeholder:
        "For example: A teenager standing by the sea looks at the distance",
      show: false,
      textareaValue: "",
      buttonStatus: false,
      showUrl: "",
      showPrompt: "",
      messageId: "",
      messageName: "",
      checkNum: "",
      checkType: "",
      showPwd: false,
      value: "",
      showKeyboard: true,
      errorInfo: "",
      anyUserinfo: {},
      timerId: "",
      loading: "",
      showCenter: false,
      aipicName: "",
      payPrice: 0,
      saveSuccess: false,
      domainUrl: "https://images.weserv.nl/?url=",
      apiKey:
        "NY7II0HHL1NKSPgtMOhRCa9E5jYDPSKFTXoozYJSDmJuvSyYtsEiZK9meCaCd9KL",
    };
  },
  methods: {
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      } else {
        this.$router.back();
      }
    },
    textareaChange() {
      let textareaValue = this.textareaValue.trim();
      if (textareaValue.length > 0) {
        this.buttonStatus = true;
      } else {
        this.buttonStatus = false;
      }
    },
    async savePic() {
      let aipicName = this.aipicName;
      let token = this.token;
      if (aipicName.length > 0) {
        try {
          let response = await this.$api.SeekGoodsName({
            name: aipicName,
            token: token,
          });
          if (response.code == 0) {
            this.show = true;
          } else {
            this.$toast(response.msg);
          }
        } catch (e) {
          console.log(e);
          this.$toast("请求失败");
        }
      } else {
        this.$toast("请输入藏品名称");
      }
    },
    async startCreate() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      let textareaValue = this.textareaValue.trim();
      console.log(textareaValue);
      let userId = this.userId;
      if (textareaValue.length > 0) {
        this.loadFunc();
        let data = this.setToken({ prompt: textareaValue, userId: userId });
        let response = await Axios.post("/discordapp/addPrompt", {
          data: data,
        });
        // let response = await Axios.post("http://127.0.0.1:3333/discordapp/addPrompt", { data: data })
        if (response.status == 200) {
          console.log("成功");
          this.setTimerId();
        }
      }
    },
    async checkPicDetail() {
      let userId = this.userId;
      let prompt = this.showPrompt;
      let num = this.checkNum;
      let messageId = this.messageId;
      let messageName = this.messageName;
      if (!userId || !prompt || !num || !messageId || !messageName) {
        this.$toast("参数异常");
        return;
      }
      let data = this.setToken({
        userId: userId,
        num: num,
        prompt: prompt,
        messageId: messageId,
        messageName: messageName,
      });
      // let response = await Axios.post("http://127.0.0.1:3333/discordapp/chooseDetail", { data: data })
      let response = await Axios.post("/discordapp/chooseDetail", {
        data: data,
      });
      if (response.status == 200) {
        this.loadFunc();
        this.setTimerId();
      }
    },
    async checkPicNew() {
      let userId = this.userId;
      let prompt = this.showPrompt;
      let num = this.checkNum;
      let messageId = this.messageId;
      let messageName = this.messageName;
      if (!userId || !prompt || !num || !messageId || !messageName) {
        this.$toast("参数异常");
        return;
      }
      let data = this.setToken({
        userId: userId,
        num: num,
        prompt: prompt,
        messageId: messageId,
        messageName: messageName,
      });
      let response = await Axios.post("/discordapp/chooseNew", { data: data });
      // let response = await Axios.post("http://127.0.0.1:3333/discordapp/chooseNew", { data: data })
      if (response.status == 200) {
        this.loadFunc();
        this.setTimerId();
      }
    },
    async picPay() {
      let userPromptUrlId = this.userPromptUrlId;
      if (!userPromptUrlId) {
        this.$toast("参数异常");
        return;
      }
      let data = this.setToken({ userPromptUrlId: userPromptUrlId });
      let response = await Axios.post("/discordapp/picPay", { data: data });
      // let response = await Axios.post("http://127.0.0.1:3333/discordapp/picPay", { data: data })
      if (response.status == 200) {
        this.loading.close();
        this.saveSuccess = true;
      }
    },
    async setPicLost() {
      let userPromptUrlId = this.userPromptUrlId;
      if (!userPromptUrlId) {
        this.$toast("参数异常");
        return;
      }
      this.loadNone();
      let data = this.setToken({ userPromptUrlId: userPromptUrlId });
      let response = await Axios.post("/discordapp/setPicLost", { data: data });
      this.loading.close();
      // let response = await Axios.post("http://127.0.0.1:3333/discordapp/setPicLost", { data: data })
      if (response.status == 200) {
        location.reload();
      }
    },
    async getPayPrice() {
      let response = await this.$api.anyInfo({
        token: this.token,
      });
      if (response.code == 0) {
        this.payPrice = response.data.chatgpt_price;
      } else {
        this.$toast(response.msg);
      }
    },
    async getPic() {
      let userId = this.userId;
      if (userId) {
        let data = this.setToken({ userId: userId });
        // let response = await Axios.post("http://127.0.0.1:3333/discordapp/getPic", { data: data })
        let response = await Axios.post("/discordapp/getPic", { data: data });
        if (response.status == 200) {
          let data = response.data.data[0];
          if (!data) {
            return;
          }
          //判断当前阶段
          if (data.status == 2) {
            //停止加载
            if (this.loading) {
              this.loading.close();
              //按钮不选中
              let tags = document.querySelectorAll(".tag");
              tags.forEach((tag) => {
                tag.classList.remove("active");
              });
            }
            clearInterval(this.timerId);
            //判断是否丢弃
            if (data.islost == 2) {
              this.picStatus == 1;
              return;
            }
            //判断是否支付
            if (data.ispay == 2) {
              this.picStatus = 1;
            } else {
              this.userPromptUrlId = data.id;
              this.showUrl = this.domainUrl + data.url;
              this.showPrompt = data.prompt;
              this.messageId = data.message_id;
              this.messageName = data.message_name;
              if (data.type == 1) {
                this.picStatus = 2;
              }
              if (data.type == 2) {
                this.picStatus = 3;
              }
            }
          }
        } else {
          this.$toast("网络请求错误");
        }
      } else {
        this.$toast("用户未登录");
      }
    },
    checkPic(event, type, num) {
      this.checkType = type;
      this.checkNum = num;
      let tags = document.querySelectorAll(".tag");
      tags.forEach((tag) => {
        tag.classList.remove("active");
      });
      event.target.classList.add("active");
    },
    setToken(data) {
      data.timestamp = parseInt(Date.parse(new Date()));
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.apiKey,
        {
          mode: CryptoJS.mode.ECB, // 模式
          padding: CryptoJS.pad.Pkcs7, // 填充
        }
      ).toString();
      return ciphertext;
    },
    loadFunc() {
      this.loading = this.$toast.loading({
        message: "作品正在生成中，预计等待60秒...",
        forbidClick: true,
        duration: 0,
      });
    },
    loadNone() {
      this.loading = this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
    },
    checkNext() {
      if (this.checkType && this.checkNum) {
        if (this.checkType == "u") {
          this.checkPicDetail();
        } else {
          this.checkPicNew();
        }
      } else {
        this.$toast("请选择类型");
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
    async onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        console.log(this.value);
        //支付
        let name = this.aipicName;
        let token = this.token;
        let pic = this.showUrl;
        let password = this.value;
        try {
          // 去掉弹框并且load
          this.showPwd = false;
          this.showCenter = false;
          this.loadFunc();
          let response = await this.$api.ChatgptCreatedGood({
            name: name,
            token: token,
            pic: pic,
            password: password,
          });
          this.value = "";
          if (response.code == 0) {
            this.picPay();
          } else {
            this.$toast(response.msg);
          }
        } catch (e) {
          console.log(e);
          this.$toast("请求失败");
        }
      }
    },
    async getAnyUserinfo() {
      if (!this.$route.query.token) {
        let randomToken = localStorage.getItem("randomToken");
        if (!randomToken) {
          let randomString = this.randomString(60);
          localStorage.setItem("randomToken", randomString);
        }
        this.userId = localStorage.getItem("randomToken");
        this.token = localStorage.getItem("randomToken");
        this.anyUserinfo.money = 0;
        this.getPic();
        console.log(this.userId);
        console.log(this.anyUserinfo.money);
        return;
      } else {
        this.token = this.$route.query.token;
      }

      //余额信息
      let params = {
        token: this.token,
      };
      await this.$api.anyUserinfo(params).then((res) => {
        if (res.code == 0) {
          this.anyUserinfo = res.data;
          this.userId = res.data.id;
          this.getPic();
        } else {
          this.$toast("账号登陆异常");
        }
      });
    },
    randomString(len) {
      var chars = "0123456789abcdefghijklmnopqrstuvwxyz";
      var result = "";
      for (var i = len; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    setTimerId() {
      this.timerId = setInterval(() => {
        this.getPic();
      }, 10000);
    },
  },
  mounted() {
    this.getPayPrice();
    this.getAnyUserinfo();
  },
};
</script>
<style lang="less" scoped>
.dcpic {
  width: 100%;
  color: #ffffff;
  background: #02192b;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
}
.app-title {
  background: #23272a !important;
}
.container {
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
  background-image: url("../../assets/images/dcpic/beijing.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dcpic-text > img {
  width: 262px;
  margin-top: 25px;
}
.dcpic-title > img {
  width: 294px;
  height: 25px;
  margin-top: 32px;
}
.dcpic-border {
  margin-top: 15px;
  background-image: url("../../assets/images/dcpic/border.png");
  background-repeat: no-repeat;
  background-size: 342px 278px;
  width: 342px;
  height: 278px;
  ::placeholder {
    color: #594b82;
  }
  > textarea {
    background-color: rgba(255, 255, 255, 0);
    width: 310px;
    height: 243px;
    margin: 18px auto;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f6edff;
    line-height: 21px;
    border: 0;
    resize: none;
  }
}
.dcpic-note {
  height: 24px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #f6edff;
  line-height: 21px;
  margin-top: 10px;
  text-align: left;
}
.dcpic-botton-1 {
  width: 100%;
  height: 44px;
  margin-top: 27px;
  margin-bottom: 14px;
  background: linear-gradient(44deg, #440edc 0%, #9c2df5 100%);
  border-radius: 5px;
  > div {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
  }
}
.dcpic-botton-2 {
  width: 100%;
  height: 44px;
  margin-top: 27px;
  background: #4a3e6e;
  border-radius: 5px;
  > div {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
  }
}
.dcpic1-border-after {
  content: "";
  position: absolute;
  top: -15px;
  right: -15px;
  width: 28px;
  height: 27px;
  border-image: linear-gradient(180deg, #c94be7, #b470e8) 1;
  border-left: 1px solid;
  transform: rotate(-46deg);
  background: #02192b;
}
.dcpic1-border-before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: -15px;
  width: 28px;
  height: 27px;
  border-image: linear-gradient(180deg, #b631ee, #d72ae8) 1;
  border-right: 1px solid;
  transform: rotate(-46deg);
  background: #02192b;
}
.dcpic1-border {
  position: relative;
  // overflow: auto;
  margin-top: 15px;
  // background-image: url("../../assets/images/dcpic/border-1.png");
  // background-repeat: no-repeat;
  // background-size: 340px 100%;

  border: 1px solid;
  border-image: linear-gradient(-60deg, #27e8e6, #d829e7, #733dfe) 10 10;
  background: radial-gradient(circle, #171d48 0%, rgba(2, 25, 43, 0) 100%);
  width: 340px;

  min-height: 370px;
  padding: 0 12px;
  box-sizing: border-box;
  > div:first-child {
    position: relative;
    margin: 11px 0;
    min-height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f6edff;
    line-height: 20px;
    text-align: left;
    word-wrap: break-word;
  }
  > div:last-child > img {
    width: 316px;
    height: 316px;
  }
  .num-tag {
    position: relative;
    width: 316px;
    height: 316px;
    margin-bottom: 12px;
    > div {
      width: 25px;
      height: 25px;
      background: #733dfe;
      opacity: 1;
      border-radius: 50%;

      height: 25px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #e1c9fc;
      line-height: 25px;
    }
    .num-tag-1 {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    .num-tag-2 {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .num-tag-3 {
      position: absolute;
      bottom: 5px;
      left: 5px;
    }
    .num-tag-4 {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
}
.dcpic1-title {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: 25px;
}
.dcpic1-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  > div {
    height: 34px;
    width: 73px;
    border: 1px solid #1a566e;
    border-radius: 6px;

    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3fced4;
    line-height: 34px;
  }
}
.tag.active {
  background: #27e8e6;
  color: #02192b;
}
.dcpic1-mint {
  width: 100%;
  margin-top: 24px;
  text-align: right;
  > div {
    height: 42px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f6edff;
    line-height: 42px;
    > span {
      font-size: 24px;
      font-weight: 400;
    }
  }
}
.dcpic1-note {
  font-size: 12px;
  margin-bottom: 14px;
}
.pay-pop {
  width: 100%;
  color: #000;
  padding: 0 17px;
  box-sizing: border-box;
}
.pay-title {
  height: 42px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 42px;
  margin-top: 27px;
}
.pay-cost {
  height: 42px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #f17a22;
  line-height: 42px;
  > span {
    font-size: 14px;
  }
}
.pay-balance {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    height: 42px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
  }
  input {
    height: 42px;
    line-height: 42px;
  }
}
.margin-bottom {
  margin-bottom: 36px;
}
.wap {
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  // height: 70%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  z-index: 999;
  color: #000;
  .wap_affirm {
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    div {
      flex: 2;
      text-align: center;
      font-size: 16px;
    }
    .cha {
      flex: 1;
      color: #666666;
      text-align: left;
      font-size: 30px;
    }
    .forgotPwd {
      flex: 1;
      text-align: right;
      font-size: 14px;
      color: rgb(rgb(7 84 211));
    }
  }
  .wap_balance {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    span {
      color: #999999;
    }
  }
  .wap_form {
    font-size: 10px;
  }
  .wap_num {
    height: 19px;
    font-size: 18px;
    font-weight: 400;
    color: #e76810;
    line-height: 20px;
    margin-top: 10px;
  }
}
/deep/.van-password-input__security li {
  border: 2px #666666 solid;
  border-radius: 5px;
}
/deep/.van-password-input {
  position: relative;
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative !important;
}
.show-center-class {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: 245px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}
.aipic-title {
  margin: 25px 0;
  height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
}
.aipic-name {
  width: 100%;
  margin: 35px 0;
  color: #000;
  > input {
    width: 100%;
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    border: 0;
    background: #f8f8f8;
    text-align: center;
  }
}
.aipic-save {
  button {
    width: 80%;
    margin: 0 auto;
  }
}
.blue {
  color: #fff;
  background: linear-gradient(88deg, #440edc 0%, #9c2df5 100%);
  border: 0;
  border-radius: 5px;
}
.gobackBtn {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  text-decoration: underline;
  color: #9a2cf5;
  line-height: 14px;
  margin: 15px auto 30px;
}
.aipic-content {
  height: 70px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333 !important;
  line-height: 50px;
}
.show-center-class-1 {
  height: 210px !important;
}
</style>
